import React, { useState, Suspense, useRef, useEffect } from "react";
import {
  Box,
  Navbar,
  Header,
  Burger,
  Text,
  Overlay,
  Select,
  Button,
  Image,
  Checkbox,
  Stack,
  Tabs,
} from "@mantine/core";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import type { OrbitControls as OrbitControlsType } from "three-stdlib";
import { ModelLoader } from "./ModelLoader";
import { getLanguageEnumByKeyForReactionModel } from "../../assets/LanguageEnums/ReactionEnumFunction";
import { getSimulationById } from "../../features/Simulations/getSimulationSlice";
import ThreeJSSimulationHandler from "../threejsSimulationHandler/ThreeJSSimulationHandler";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

const Add =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-32-34-006Z.png";
const Back =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-32-52-753Z.png";
const Next =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-33-13-290Z.png";
const Close =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-33-28-832Z.png";
const Anno =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-33-49-541Z.png";
const Reset =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-34-11-092Z.png";
const Play =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-27-44-682Z.png";
const Pause =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-28-03-310Z.png";
const closeImg =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-28-22-597Z.png";
const AccountIcon =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-27T10-51-25-879Z.png";
const maincloseImg = 
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-19T08-08-49-765Z.png";
const upArrowImg =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-08-17T06-18-10-702Z.png";
const AWSModelLink = process.env.REACT_APP_AWS_MODEL_LINK;

const ModelWithJSONSimulation = ({data}: {data:any}) => {
  const navigate = useNavigate();
  const [simulationData, setSimulationData] = useState<any>(data);
  const [opened, setOpened] = useState(false);
  const [selectedId, setSelectedId] = useState(simulationData[0].id);
  const [selectedTitle, setSelectedTitle] = useState<{en: string, hi: string}>(simulationData[0].title);
  const [selectedSteps, setSelectedSteps] = useState(simulationData[0].steps);
  const [selectedStep, setSelectedStep] = useState(simulationData[0].steps[0].id);
  const [selectedModelPath, setModelPath] = useState(simulationData[0].modelPath);
  const [selectedScale, setSelectedScale] = useState(simulationData[0].steps[0].initialScale);
  const [selectedRotation, setSelectedRotation] = useState(simulationData[0].steps[0].initialRotation);
  const [selectedPosition, setSelectedPosition] = useState(simulationData[0].steps[0].initialPosition);
  const [option1Checked, setOption1Checked] = useState<boolean>(false);
  const [option2Checked, setOption2Checked] = useState<boolean>(false);
  const [selectedStepDetails, setSelectedStepDetails] = useState(simulationData[0].steps[0]);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [drawingMode, setDrawingMode] = useState(false);
  const controlsRef = useRef<OrbitControlsType>(null);
  const drawCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [controls, setControls] = useState<boolean>(true);
  const [lastPosition, setLastPosition] = useState<{x: number; y: number;} | null>(null);
  const [hideState, setHideState] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [animate, setAnimate] = useState<boolean>(true);
  const [isAccountOpen, setAccountOpen] = useState<boolean>(false);
  const [userLanguage, setUserLanguage] = useState<string>('en');
  const title = opened ? "Close_navigation" : "Open_navigation";
  const currentPath = window.location.pathname;
  const segments = currentPath.split("/").filter(Boolean);
  const lastSegment = segments[segments.length - 1];
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [isDescToggle, setIsDescToggle] = useState<boolean>(false);

  const handleToggleBox = () => {
    setIsDescToggle(!isDescToggle);
  };
  useEffect(() => {
    const canvas = drawCanvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
    console.log(simulationData);
  }, []);

  useEffect(() => {
    const handleWheel = (event: any) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    const canvas = document.querySelector("canvas");
    if (canvas) {
      canvas.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  const handleDrawClick = () => {
    setDrawingMode(!drawingMode);
    setControls(!controls);
  };


  const handleMenuItemClick = (title:{en: string, hi: string}, steps: any[], id: number,modelPath:string) => {
    setSelectedId(id);
    setSelectedStepDetails(steps[0]);
    setSelectedScale(steps[0].initialScale);
    setSelectedPosition(steps[0].initialPosition);
    setSelectedRotation(steps[0].initialRotation);
    setSelectedTitle(title);
    setSelectedSteps(steps);
    setSelectedStep(steps[0].id);
    setModelPath(modelPath)
    setOpened(false);
    setAnimate(true);
    setIsPlaying(true);
  };

  const handleStepChange = (value: string) => {
    const step = selectedSteps.find((step:any) => step.id === value);
    if (step) {
      setSelectedStep(value);
      setSelectedStepDetails(step);
      setSelectedScale(step.initialScale);
      setSelectedPosition(step.initialPosition);
      setSelectedRotation(step.initialRotation);
    }
  };

  const handleBackButtonClick = () => {
    const currentIndex = selectedSteps.findIndex((step: any) => step.id === selectedStep);
    if (currentIndex > 0) {
      const previousStep = selectedSteps[currentIndex - 1];
      setSelectedStep(previousStep.id);
      setSelectedStepDetails(previousStep);
      setSelectedScale(previousStep.initialScale);
      setSelectedPosition(previousStep.initialPosition);
      setSelectedRotation(previousStep.initialRotation);
    }
  };
  
  const handleNextButtonClick = () => {
    const currentIndex = selectedSteps.findIndex((step: any) => step.id === selectedStep);
    if (currentIndex < selectedSteps.length - 1) {
      const nextStep = selectedSteps[currentIndex + 1];
      setSelectedStep(nextStep.id);
      setSelectedStepDetails(nextStep);
      setSelectedScale(nextStep.initialScale);
      setSelectedPosition(nextStep.initialPosition);
      setSelectedRotation(nextStep.initialRotation);
    }
  };

  const handleAddButtonClick = () => {
    setIsBoxVisible(true);
  };

  const handleCloseButtonClick = () => {
    setIsBoxVisible(false);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (drawingMode) {
      setIsDrawing(true);
      setLastPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseUp = () => {
    if (drawingMode) {
      setIsDrawing(false);
      setLastPosition(null);
    }
  };
  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDrawing && drawingMode && lastPosition) {
      const canvas = drawCanvasRef.current;
      const context = canvas?.getContext("2d");
      if (context) {
        context.strokeStyle = "white";
        context.lineWidth = 2;
        context.beginPath();
        context.moveTo(lastPosition.x, lastPosition.y);
        context.lineTo(event.clientX, event.clientY);
        context.stroke();
        setLastPosition({ x: event.clientX, y: event.clientY });
      }
    }
  };

  const handleResetButton = () => {
    setControls(true);
    setDrawingMode(false);
    const canvas = drawCanvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
    if (controlsRef.current) {
      controlsRef.current.reset();
    }
    handleStepChange(selectedSteps[0].id)
  };

  const handlehideButton = () => {
    setHideState(!hideState);
  };
  const handleAnimationButton = () => {
    setIsPlaying(!isPlaying);
  };
  const handlereseerwer = () => {
    setIsPlaying(false);
  };

  const option1Function = (option1: boolean)=>{
    if(option1){
      setOption1Checked(option1);      
      setOption2Checked(false);
    }else if(!option1){
      setOption1Checked(option1);      
      setOption2Checked(false);
    }

  }
  const option2Function = (option2: boolean)=>{
    if(option2){
      setOption1Checked(false);      
      setOption2Checked(option2);
    }else if(!option2){
      setOption1Checked(false);      
      setOption2Checked(option2);      
    }
  }

  useEffect(()=>{
    if(option1Checked){
      // setModelPath(selectedStepDetails.options?.option1.modelPath ?? selectedStepDetails.modelPath);
      setSelectedScale(selectedStepDetails.options?.option1.scale ?? selectedStepDetails.initialScale);
      setSelectedPosition(selectedStepDetails.options?.option1.position ?? selectedStepDetails.initialPosition);
      setSelectedRotation(selectedStepDetails.options?.option1.rotation ?? selectedStepDetails.initialRotation);

    }else if(option2Checked){
      // setModelPath(selectedStepDetails.options?.option2.modelPath ?? selectedStepDetails.modelPath);
      setSelectedScale(selectedStepDetails.options?.option2.scale ?? selectedStepDetails.initialScale);
      setSelectedPosition(selectedStepDetails.options?.option2.position ?? selectedStepDetails.initialPosition);
      setSelectedRotation(selectedStepDetails.options?.option2.rotation ?? selectedStepDetails.initialRotation);
    }else if(!option1Checked && !option2Checked){
      // setModelPath(selectedStepDetails.modelPath);
      setSelectedScale(selectedStepDetails.initialScale);
      setSelectedPosition(selectedStepDetails.initialPosition);
      setSelectedRotation(selectedStepDetails.initialRotation);
    }
  },[option1Checked, option2Checked, selectedStepDetails])

  const handleBackButton = ()=>{
    const currentPath = window.location.pathname;
    const segments = currentPath.split('/');
    const newPathname = segments.slice(0, -3).join('/');
    navigate(`${newPathname}`);
    }

  return (
    <Box
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#282828",
        position: "relative",
        overflowY: "hidden"
      }}
    >
      <ThreeJSSimulationHandler sim_id={lastSegment} data={null} />
      <Box sx={{position: 'fixed', top: 15, right: 10, zIndex: 1000, display: 'flex', flexDirection: 'column', gap: 10}}>
  <Button onClick={handleBackButton} variant='outline' style={{padding: 0, border: '0.1px solid transparent', backgroundColor: 'transparent'}}>
      <img src={maincloseImg} alt="building" style={{ width: isSmallScreen ? 25 : 35, height: isSmallScreen ? 25 : 35,}} />
    </Button>
    <Button onClick={()=>setAccountOpen(true)} variant='outline' style={{ padding: 0, width:isSmallScreen ? 35 : 50, height: isSmallScreen ? 35 :50, border: '0.1px solid transparent',backgroundColor: "transparent" }}>
      <img src={AccountIcon} alt="AccountIcon" style={{width: '100%', height: '100%', objectFit: 'cover', }} />
    </Button>
    </Box>

      <Header
        height="9vh"
        style={{
          backgroundColor: "#282828",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          position: "relative",
          zIndex: 300,
          borderBottom: "2px solid #282828",
        }}
      >
        <Burger
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          title={getLanguageEnumByKeyForReactionModel({
            key: title,
            LanguageId: userLanguage,
          })}
          size="sm"
          color="white"
          style={{ position: "absolute", left: "16px" }}
          aria-label="Toggle navigation"
        />
        {opened && (
          <Text
            style={{
              color: "white",
              fontSize: "20px",
              position: "absolute",
              left: "56px",
            }}
          >
          {getLanguageEnumByKeyForReactionModel({
                  key: "Th_Title",
                  LanguageId: userLanguage,
                })}
          </Text>
        )}
        <Text
          style={{
            fontWeight: "bold",
            color: "white",
            fontSize: "20px",
            textAlign: "center",
            flex: 1,
          }}
        >
          {userLanguage === "hi" ? selectedTitle.hi:selectedTitle.en}
        </Text>
      </Header>
      {isAccountOpen && (
        <>
          <Overlay
            opacity={0.8}
            color="#000"
            zIndex={1003}
            onClick={() => setAccountOpen(false)}
          />
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 1003,
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              width: "400px",
              height: "250px",
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                height: "25%",
              }}
            >
              <Text sx={{ fontWeight: 700, fontSize: "25px" }}>
                {getLanguageEnumByKeyForReactionModel({
                  key: "Preferences",
                  LanguageId: userLanguage,
                })}
              </Text>
              <Box style={{ cursor: "pointer" }}>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => setAccountOpen(false)}
                  src={closeImg}
                  width={15}
                  height={15}
                />
              </Box>
            </Box>
            <Box sx={{ height: "70%", width: "100%" }}>
              <Tabs defaultValue="overview">
                <Tabs.List>
                  <Tabs.Tab value="overview">
                    {getLanguageEnumByKeyForReactionModel({
                      key: "Overview",
                      LanguageId: userLanguage,
                    })}
                  </Tabs.Tab>
                  <Tabs.Tab value="languages">
                    {getLanguageEnumByKeyForReactionModel({
                      key: "Languages",
                      LanguageId: userLanguage,
                    })}
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="overview" pt="lg">
                  <Text>
                    {getLanguageEnumByKeyForReactionModel({
                      key: "Preference_Content",
                      LanguageId: userLanguage,
                    })}
                  </Text>
                </Tabs.Panel>
                <Tabs.Panel value="languages" pt="lg">
                  <Box
                    sx={{
                      width: "100%",
                      height: "70px",
                      border: "2px solid black",
                      borderRadius: "10px",
                      padding: "7px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: "10px",
                        height: "100%",
                        width: "45%",
                        border: "1px solid transparent",
                        backgroundColor:
                          userLanguage == "en" ? "#BACEF4" : "transparent",
                      }}
                      variant="outline"
                      onClick={() => setUserLanguage("en")}
                    >
                      <Text sx={{ fontSize: "22px" }} fw={700} c="black">
                        English
                      </Text>
                    </Button>
                    <Button
                      style={{
                        borderRadius: "10px",
                        height: "100%",
                        width: "45%",
                        border: "1px solid transparent",
                        backgroundColor:
                          userLanguage == "hi" ? "#BACEF4" : "transparent",
                      }}
                      variant="outline"
                      onClick={() => setUserLanguage("hi")}
                    >
                      <Text sx={{ fontSize: "22px" }} fw={700} c="black">
                        हिंदी
                      </Text>
                    </Button>
                  </Box>
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Box>
        </>
      )}
      {opened && (
        <>
          <Overlay
            opacity={0.5}
            color="#000"
            zIndex={200}
            onClick={() => setOpened(false)}
          />
          <Navbar
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#6b6b6b #2b2b2b",
              overflowY: "auto",
              marginTop: "9vh",
              backgroundColor: "#282828",
              color: "white",
              width: "200px",
              height: "91vh",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 400,
              borderRight: "2px solid #282828",
            }}
          >
            {simulationData.map((item: any) => (
              <Text
                key={item.id}
                onClick={() => handleMenuItemClick(item.title, item.steps, item.id,item.modelPath)}
                style={{
                  fontSize: "18px",
                  padding: "15px",
                  cursor: "pointer",
                  transition: "all 0.3s",
                  backgroundColor:
                    selectedTitle === item.title
                      ? "rgba(255, 255, 255, 0.3)"
                      : "transparent",
                }}
              >
                {item.label}
              </Text>
            ))}
          </Navbar>
        </>
      )}

      <Box
        style={{
          background: simulationData.find((item: any)=>item.id === selectedId)?.backgroundGradient,
          height: "91vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Canvas
          style={{
            position: "absolute",
            width: "100vw",
            height: "91vh",
            zIndex: 50,
          }}
          camera={{ position: [0, 0, 10], fov: 20 }}
        >
          <Suspense fallback={null}>
            <ambientLight intensity={1} />
            <directionalLight position={[5, 5, 1]} intensity={1} />
            <ModelLoader
              modelPath={AWSModelLink+selectedModelPath}
              position={[selectedPosition[0],selectedPosition[1], selectedPosition[2]]}
              scale={[selectedScale[0], selectedScale[1], selectedScale[2]]}
              rotation={[selectedRotation[0], selectedRotation[1], selectedRotation[2]]}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              animate={animate}
              selectedStepDetails={selectedStepDetails}
            />
          </Suspense>
          <OrbitControls
            ref={controlsRef}
            enablePan={true}
            enableRotate={selectedStepDetails.isRotating ?? true}
          />
        </Canvas>
        <canvas ref={drawCanvasRef} style={{ position: "absolute", width: "100vw", height: "91vh", pointerEvents: drawingMode ? "auto" : "none",
            display: drawingMode ? "block" : "none", zIndex: 150,}} />
<Box
style={{
        position: "absolute",
        top: isSmallScreen ? "" : "50%",
        bottom: isSmallScreen ? "0" : "",
        left: isSmallScreen ? "50%" : "1%",
        transform: isSmallScreen ?  
        (isDescToggle ? "translate(-50%, 0%)" :"translate(-50%, 78%)") : "translateY(-50%)",
        padding: "16px 16px 10px",
        borderRadius: "8px",
        color: "white",
        height: "560px",
        zIndex: 200,
        transition: "transform 0.3s ease-in-out",
}}> 
<Button  onClick={handleToggleBox} style={{zIndex: 200, width: "40px", height: "40px", padding: 0,transform: "translateX(-130px)", backgroundColor: 'transparent'}}>
          <img src={upArrowImg} alt="up arrow" style={{width: '100%', height: '100%', objectFit: 'cover',transform: isDescToggle ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease-in-out' }}/>
          </Button> 
        <Box
          style={{
            position: "absolute",
            top: isSmallScreen ? "": "50%",
            bottom: isSmallScreen ? "0" : "",
            left: isSmallScreen ? "50%": "1%",
            transform: isSmallScreen ? "translateX(-50%)" :"translateY(-50%)",
            backgroundColor: "#454545",
            padding: "16px 16px 10px",
            borderRadius: "8px",
            color: "white",
            width: "300px",
            height:"500px",
            zIndex: 200,
            overflow: 'hidden',
          }}
        >  
          <Select
            placeholder="Pick a step"
            value={selectedStep}
            onChange={handleStepChange}
            data={selectedSteps.map((step: any) => ({
              value: step.id,
              label: userLanguage == "hi" ? step.label.hi : step.label.en,
            }))}
            style={{ marginBottom: "10px", height:'10%' }}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: '10%'
            }}
          >
            <Text
              style={{
                fontSize: "20px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
            >
            {getLanguageEnumByKeyForReactionModel({
                      key: "Description",
                      LanguageId: userLanguage,
                    })}
              
            </Text>
            <Button
              onClick={handleAddButtonClick}
              style={{ padding: "2px", backgroundColor: "transparent" }}
            >
              <Image src={Add} alt="add button" width={28} height={28} />
            </Button>
          </Box>

          <Box
            style={{
              backgroundColor: "#3B3B3B",
              height: "65%",
              overflowY: 'auto',
              marginTop: "10px",
              padding: "10px",
              border: "2px solid gray",
              borderRadius: "10px",
            }}
          >
            <Text>{userLanguage === "hi"?selectedStepDetails.heading.hi: selectedStepDetails.heading.en}</Text>
            <Text>{userLanguage === "hi" ? selectedStepDetails.content.hi: selectedStepDetails.content.en}</Text>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              height: '15%',
            }}
          >
            <Button  onClick={handleBackButtonClick}
              style={{
                width: 50,
                height: 50,
                padding: 0,
                backgroundColor: "transparent",
              }}
            >
              <Image src={Back} alt="back button" width={40} height={40} />
            </Button>
            <Button onClick={handleNextButtonClick}
              style={{
                width: 50,
                height: 50,
                padding: 0,
                backgroundColor: "transparent",
                marginLeft: "5px",
              }}
            >
              <Image src={Next} alt="Next button" width={40} height={40} />
            </Button>
          </Box>
        </Box>
</Box>  
        {isBoxVisible && (<>
          {isSmallScreen && 
            <Overlay
                    opacity={0.6}
                    color="#000"
                    zIndex={500}
                    onClick={() =>setIsBoxVisible(false)}
                  />
            }
          <Box
            style={{
              zIndex: 500,
              position: "absolute",
              top: "50%",
              left: isSmallScreen ? "50%" : "calc(1% + 300px + 10px)",
              transform: isSmallScreen ? "translate(-50%, -50%)" :"translateY(-50%)",
              backgroundColor: "#454545",
              padding: "16px 16px 10px",
              borderRadius: "8px",
              color: "white",
              width: "300px",
              height: "62%",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "20px", marginLeft: "5px" }}>
                {userLanguage === "hi" ? selectedStepDetails.heading.hi : selectedStepDetails.heading.en}
              </Text>
              <Button
                onClick={handleCloseButtonClick}
                style={{ padding: "2px", backgroundColor: "transparent" }}
              >
                <Image src={Close} alt="close button" width={28} height={28} />
              </Button>
            </Box>

            <Text
              style={{
                marginTop: "10px",
                padding: "2px",
                overflowY: "auto",
                maxHeight: "85%",
                scrollbarWidth: "thin",
                scrollbarColor: "#6b6b6b #2b2b2b",
              }}
            >
                {userLanguage === "hi" ? selectedStepDetails.subContent.hi : selectedStepDetails.subContent.en}
            </Text>
          </Box>
          </>
        )}

        <Box
          style={{
            bottom: "5%",
            right: "2%",
            position: "absolute",
            zIndex: 400,
            display: "flex",
            alignItems: "end",
          }}
        >
         {selectedStepDetails.isOptionVisible && <Box
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              paddingBottom: "5px",
              marginRight: "10px",
            }}
          >
            <Text
              align="center"
              style={{ marginBottom: "5px", fontSize: "1.5rem" }}
            >
            {getLanguageEnumByKeyForReactionModel({
                      key: "Options",
                      LanguageId: userLanguage,
                    })}
            </Text>
            <Checkbox color="indigo" size="md" 
            label={userLanguage === "hi" ? selectedStepDetails.options?.option1.label.hi : selectedStepDetails.options?.option1.label.en}
            checked={option1Checked} 
            onChange={(event) => option1Function(event.currentTarget.checked)}
              style={{
                width: "250px",
                paddingLeft: "10px",
                marginBottom: "10px",
              }}
            />
            <Checkbox
              color="indigo"
              size="md" checked={option2Checked} 
              onChange={(event) => option2Function(event.currentTarget.checked)}
              label={userLanguage === "hi" ? selectedStepDetails.options?.option2.label.hi : selectedStepDetails.options?.option2.label.en}
              style={{
                width: "250px",
                paddingLeft: "10px",
                marginBottom: "10px",
              }}
            />
          </Box>}
          <Stack
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems:"center",
            }}
          >
            <Button
              onClick={handleAnimationButton}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                width: isSmallScreen ? 35 :50,
                height: isSmallScreen ? 35 :50,
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            >
              <Image
                src={isPlaying ? Pause : Play}
                alt="Animation"
                width={isSmallScreen ? 21 :28}
                height={isSmallScreen ? 21 :28}
              />
            </Button>
            <Button
              onClick={handleResetButton}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                width: isSmallScreen ? 35 :50,
                height: isSmallScreen ? 35 :50,
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            >
              <Image src={Reset} alt="Reset" width={isSmallScreen ? 21 :28} height={isSmallScreen ? 21 :28} />
            </Button>
            <Button
              onClick={handleDrawClick}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                width: isSmallScreen ? 35 :50,
                height: isSmallScreen ? 35 :50,
                backgroundColor: drawingMode ? "#4B65F6" : "white",
                borderRadius: "50%",
              }}
            >
              <Image
                src={Anno}
                alt="Annotation"
                width={isSmallScreen ? 21 :28}
                height={isSmallScreen ? 21 :28}
                style={{ filter: drawingMode ? "invert(1)" : "invert(0)" }}
              />
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};



const ElementModelLoader: React.FC = ()=>{
  const [dataRecieved, setDataReceived] = useState<any>();
  const currentPath = window.location.pathname;
  const segments = currentPath.split("/").filter(Boolean);
  const lastSegment = segments[segments.length - 1];
  var datarecieved = false;

  useEffect(() => {
    getSimulationById({id:lastSegment})
      .then((simulation: any) => {
        datarecieved = true
        console.log(simulation.data[0].modelPath)
        setDataReceived(simulation.data);
        return(
          <ModelWithJSONSimulation data={simulation.data} />
        )
      })
      .catch((err) => {
        console.log(err);
        return(
          <div>Model Not Found</div>
        )
      });
  }, [lastSegment]);
  return (
    <>
    {dataRecieved ? <><ModelWithJSONSimulation data={dataRecieved}/></> : <>Model Not Found</>}
    </>
  )
}
export default ElementModelLoader;
